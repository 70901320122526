// 首页相关
import axiosApi, {baseURL} from "./axiosApi";
const BASE_URL = baseURL();

// 首页
export const getBannerList = params => axiosApi.post(`${BASE_URL}/banner/query`, params);//获取banner

export const searchLog = params => axiosApi.get(`${BASE_URL}/search/enter/homepage`, params); //搜索模块-日志记录

// pv
export const getPV = params => axiosApi.get(`${BASE_URL}/page/homePv`, params);
export const getDownloadPV = params => axiosApi.get(`${BASE_URL}/page/downloadPv`, {params});
export const downloadExcel = params => axiosApi.get(`${BASE_URL}/company/download`, {params});


