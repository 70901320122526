import axios from 'axios';

// 创建 axios 实例
let axiosApi = axios.create({
    proxy: true,
    timeout: 80000,  // 请求超时时间
    crossDomain: true,//设置cross跨域
    withCredentials: true, //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
    headers: {
        // version: 'NEW_ES2',
        device: 'pc'
    }
});

axiosApi.interceptors.request.use(config => {
        /* if (process.env.NODE_ENV === 'development') {//开发环境
            config.headers = {
                'X-Real-Ip': '10.2.134.212',
                'X-Forwarded-For': '10.10.10.10'
            }
        } */
        return config;
    }, error => {
        // 请求错误处理
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axiosApi.interceptors.response.use(response => {
        let {data} = response;
        if(response.headers['content-disposition']) {
            data.fileName = response.headers['content-disposition']
        }
        return data;
    }, error => {
        let info = {};
        // let {status, statusText, data} = error.response;
        // if (!error.response) {
        //     info = {
        //         code: 5000,
        //         msg: '网络错误'
        //     }
        // } else {
        //     // 此处整理错误信息格式
        //     info = {
        //         code: status,
        //         data: data,
        //         msg: statusText
        //     }
        // }

        return info;
    }
);

export default axiosApi;

export const baseURL = () => {
    let href = window.location.href
    let url
    if (href.includes('us.tianyancha.com')) {
        url = '//us.tianyancha.com/api'
    } else {
        url = '//10.100.11.131:8888/api'
    }
    return url
}
